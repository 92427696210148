<template>
  <div class="about">
    <div class="member">
      <div class="member-head">
         <span class="iconfont icon-fanhuijiantou" @click="goHistotry()"></span>
      </div>
      <div class="member-middle">
        <div class="member-vipCard">
          <div class="vipCard-top">
            <div class="vipCard-avater">
              <div>
                <img src="../assets/images/u425.png" />
              </div>
              <div class="name">李光洙</div>
            </div>
            <div class="vipCard-jifen">
              <span>2500</span>
              <span>积分</span>
            </div>
          </div>
          <div class="vipCard-bottom">
            <div class="vipCard-info">
              <div>
                <img src="../assets/images/u424.png" />
              </div>
              <div class="one">1</div>
              <div class="name">会员权益</div>
            </div>
            <div class="vipCard-time">
              <span>2020-10-24会员到期</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="common">
      <div class="common-container">
        <h5>订单</h5>
        <div class="common-content">
          <div class="order" @click="goToBePaid()">
            <i class="iconfont icon-u443"></i>
            <span class="number">1</span>
            <p>待支付</p>
          </div>
          <div class="order" @click="goToBeShipped()">
            <i class="iconfont icon-u446"></i>
            <!-- <span>1</span> -->
            <p>待发货</p>
          </div>
          <div class="order" @click="goToBeReceived()">
            <i class="iconfont icon-u449"></i>
            <!-- <span>1</span> -->
            <p>待收货</p>
          </div>
        </div>
      </div>
      <div class="common-container">
        <h5>功能</h5>
        <div class="common-content">
          <!-- <div class="setting" @click="goSignIn()">
            <i class="iconfont icon-u468"></i>
            <span class="number">1</span>
            <p>签到</p>
          </div> -->
          <div class="setting" @click="goMyRegistration()">
            <i class="iconfont icon-u458"></i>
            <!-- <span>1</span> -->
            <p>我的报名</p>
          </div>
          <div class="setting" @click="goMyAddress()">
            <i class="iconfont icon-u461"></i>
            <!-- <span>1</span> -->
            <p>收货地址</p>
          </div>
          <div class="setting" @click="goMyProfile()">
            <i class="iconfont icon-u455"></i>
            <!-- <span>1</span> -->
            <p>我的档案</p>
          </div>
          <div class="setting" @click="goEncounterMgmt()">
            <i class="iconfont icon-u464"></i>
            <!-- <span>1</span> -->
            <p>就诊人管理</p>
          </div>
        </div>
      </div>
    </div>
    <!-- navBar组件 -->
    <nav-bar :members="members"/>
  </div>
</template>

<script>
import NavBar from '../components/navBar.vue';
export default {
  name: 'member',
  components: {
    NavBar
  },
  data() {
    return {
      members: 1
    }
  },
  methods: {
    goHistotry() {
      this.$router.go(-1)
    },
    goSignIn() {
      this.$router.push('/SignIn');
    },
    goToBePaid() {
      this.$router.push('/toBePaid');
    },
    goToBeShipped() {
      this.$router.push('/toBeShipped');
    },
    goToBeReceived() {
      this.$router.push('/toBeReceived');
    },
    goMyRegistration() {
      this.$router.push('/MyRegistration');
    },
    goMyAddress() {
      this.$router.push('/MyAdress');
    },
    goMyProfile() {
      this.$router.push('/MyProfile');
    },
    goEncounterMgmt() {
      this.$router.push('/EncounterMgmt');
    }
  },
}
</script>

<style lang="less" scoped>
@import '../assets/css/member.less';
</style>